import styles from "./MorningNote.module.scss";
import { isOrtexApp, nFormatter } from "@/lib/utils";
import { getLatestMorningNote, getMorningNoteData } from "@/controllers/morning-note";
import { blackLighterState, blackState, dividerState, greyDarkerState, greyLighterState, lighterBackgroundColorState, negativeState, ortexColorState, positiveState, textColorState, themeState, whiteDarkerState, whiteMediumState } from "@/lib/store";
import { LatestMorningNote } from "@/models/morning-note";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Color from "@/lib/Color";
import { ModuleContext } from "@/lib/Context";
import { Chip, CircularProgress, Divider, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import Skeleton from "../skeleton/Skeleton";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Capacitor } from "@capacitor/core";
import { getStockMovesFallers, getStockMovesRisers } from "@/controllers/stock-moves";
import { eventFamilies } from "@/lib/vars";
import ReactCountryFlag from "react-country-flag";
import MarketMovers from "./market-movers/MarketMovers";
import TradingSignals from "./trading-signals/TradingSignals";
import Events from "./events/Events";
import ShortScores from "./short-scores/ShortScores";
import Options from "./options/Options";
import DataHighlights from "./data-highlights/DataHighlights";
import { getDataHightlightsStocks } from "@/controllers/data-highlights";

interface Props {
    note?: LatestMorningNote;
    staff?: boolean;
    setFilters?: (filters: JSX.Element) => void;
    admin?: boolean;
    openEditor?: boolean;
    setOpenEditor?: (value: boolean) => void;
    setNote?: (value: any) => void;
}

const MorningNote = ({ note, admin, openEditor }: Props) => {
    const { obfuscated, setObfuscated } = useContext(ModuleContext);

    const textColor = useRecoilValue(textColorState);
    const lighterBackgroundColor = useRecoilValue(lighterBackgroundColorState);
    const theme = useRecoilValue(themeState);
    const blackLighter = useRecoilValue(blackLighterState);
    const greyDarker = useRecoilValue(greyDarkerState);
    const greyLighter = useRecoilValue(greyLighterState);
    const whiteDarker = useRecoilValue(whiteDarkerState);
    const whiteMedium = useRecoilValue(whiteMediumState);
    const black = useRecoilValue(blackState);
    const positive = useRecoilValue(positiveState);
    const negative = useRecoilValue(negativeState);
    const ortexColor = useRecoilValue(ortexColorState);

    const [latestMorningNote, setLatestMorningNote] = useState<LatestMorningNote>();
    const [formattedNote, setFormattedNote] = useState<string>();

    const [stockMovers, setStockMovers] = useState<any[]>([]);
    const [tradingSignals, setTradingSignals] = useState<any[]>([]);
    const [events, setEvents] = useState<any[]>(null);
    const [shortScores, setShortScores] = useState<any[]>([]);
    const [options, setOptions] = useState<any[]>([]);
    const [highlightsData, setHighlightsData] = useState<any[]>([]);

    const isLaptopOrGreater = useMediaQuery("(min-width: 1279px)");
    const is4k = useMediaQuery("(min-width: 3800px)");
    const isDesktop = useMediaQuery("(min-width: 1919px)");
    const isMobile = useMediaQuery("(max-width: 769px)");

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingStocks, setIsLoadingStocks] = useState(true);

    const dividerColor = useRecoilValue(dividerState);

    useEffect(() => {
        Promise.all([getDataHightlightsStocks(), getLatestMorningNote(1)])
            .then(([highlightsResponse, note]) => {
                const { data } = highlightsResponse;
                setHighlightsData(data);

                const { obfuscated, note: noteText } = note;
                setObfuscated && setObfuscated(obfuscated);

                const formattedNote = noteText.replace(/(href=")(https:\/\/(app|beta|legacy).ortex.com)(.*?")/g, "$1$4");

                setLatestMorningNote({ ...note, note: formattedNote });
                setFormattedNote(formattedNote);
            })
            .catch((err) => {
                console.error("Error fetching morning note data:", err);
            })
            .finally(() => {
                setIsLoading(false);
            });

        Promise.all([getStockMovesRisers({ page_size: 5, page: 1, universe_id: 100, filter_options: {} }), getStockMovesFallers({ page_size: 5, page: 1, universe_id: 100, filter_options: {} })])
            .then((res) => {
                const rows = res.reduce((acc, cur) => {
                    return [...acc, ...cur.rows];
                }, []);

                const stocks = rows.map((row) => {
                    return { id: row.id, ticker: row.ticker[0], priceChange: row.priceChange };
                });
                setStockMovers(stocks);
            })
            .finally(() => {
                setIsLoadingStocks(false);
            });

        getMorningNoteData().then((data) => {
            setTradingSignals(data.trading_signals);
            setEvents(data.events);
            setShortScores(data.short_scores);
            setOptions(data.options);
        });
    }, []);

    return (
        <div className={styles.MorningNote}>
            <style jsx global>{`
                .${styles.date} {
                    color: ${theme === "dark" ? whiteDarker : blackLighter};
                }

                .${styles.editor} h1 {
                    color: "${theme === "dark" ? whiteDarker : blackLighter}";
                }

                .${styles.editor} h2 {
                    border-top: 1px solid ${theme === "dark" ? whiteMedium : (new Color(whiteDarker).brighten(-0.05).get() as string)};
                    color: "${theme === "dark" ? whiteDarker : blackLighter}";
                }

                .${styles.editor} p {
                    color: ${theme === "dark" ? whiteDarker : black};
                }
                a {
                    color: ${ortexColor};
                }
                div#note p {
                    margin-bottom: 0.5rem;
                }
            `}</style>

            {isLoading ? (
                <Skeleton variant="rounded" width="100%" height="100%" loading={isLoading} />
            ) : (
                <Box sx={{ height: "100%", overflowY: "hidden" }}>
                    {latestMorningNote?.published && (
                        <Box sx={{ height: "100%", overflowY: "auto" }}>
                            <Box id="note" sx={{ marginY: 1 }}>
                                {highlightsData.length ? (
                                    <>
                                        <Box sx={{ height: "32px", marginY: 1, position: "sticky" }}>
                                            <Divider
                                                sx={{
                                                    "&::before, &::after": {
                                                        borderColor: dividerColor,
                                                    },
                                                }}
                                            >
                                                <Chip label={dayjs(latestMorningNote.note_date).format("dddd") + "'s Data Highlights"} sx={{ backgroundColor: dividerColor, color: textColor }} />
                                            </Divider>
                                        </Box>

                                        <Box sx={{ height: "calc(100% - 32px)", px: 1 }}>
                                            <DataHighlights highlightsData={highlightsData} />
                                        </Box>
                                    </>
                                ) : null}
                            </Box>
                            <Box id="note" sx={{ marginY: 1 }}>
                                <Box sx={{ height: "32px", marginY: 1, position: "sticky" }}>
                                    <Divider
                                        sx={{
                                            "&::before, &::after": {
                                                borderColor: dividerColor,
                                            },
                                        }}
                                    >
                                        <Chip label={"Morning Note"} sx={{ backgroundColor: dividerColor, color: textColor }} />
                                    </Divider>
                                </Box>
                                <Box sx={{ height: "calc(100% - 32px)", px: 1 }}>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} linkTarget={isOrtexApp() ? "_self" : "_blank"}>
                                        {formattedNote}
                                    </ReactMarkdown>
                                </Box>
                            </Box>
                            <MarketMovers stockMovers={stockMovers} is4k={is4k} isDesktop={isDesktop} isLoadingStocks={isLoadingStocks} />
                            {tradingSignals?.length ? <TradingSignals tradingSignals={tradingSignals} /> : null}
                            {events?.length ? <Events events={events} /> : null}
                            {shortScores?.length ? <ShortScores shortScores={shortScores} is4k={is4k} isDesktop={isDesktop} isLaptopOrGreater={isLaptopOrGreater} isMobile={isMobile} /> : null}
                            {options?.length ? <Options options={options} is4k={is4k} isDesktop={isDesktop} /> : null}
                        </Box>
                    )}
                </Box>
            )}
        </div>
    );
};

export default MorningNote;
