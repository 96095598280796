import React from "react";
import Image from "next/image";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { dividerState, textColorState, themeState } from "@/lib/store";
import { dateParser } from "@/lib/utils";
import noBackgroundStocks from "@/models/noBackgroundStocks.json";
import styles from "./DataHighlights.module.scss";
import { useDataHighlights } from "./hooks/useDataHighlights";
import { useImageHandler } from "src/hooks/useImageHandler";
import Link from "next/link";

const formatCommentWithDate = (comment: string, parameters: number[]) => {
    let formattedComment = "";

    if (comment.includes("$%$")) {
        const formattedDate = dateParser(dayjs(parameters[0]), "D MMM YYYY [at] HH:mm");
        formattedComment = comment.replace("$%$", formattedDate);
    } else formattedComment = comment;

    return formattedComment;
};

export default function DataHighlights({ highlightsData }) {
    const dividerColor = useRecoilValue(dividerState);
    const textColor = useRecoilValue(textColorState);
    const theme = useRecoilValue(themeState);

    const { data, fetchMoreData, loading } = useDataHighlights(highlightsData);
    const { imgError, blankImg, handleImgError, handleImgLoad } = useImageHandler();

    if (!data.length) return null;

    return (
        <div>
            <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1, mb: 1 }}>
                {data.map((element) => (
                    <Link href={`/s/${element.exchange}/${element.ticker}`} key={element.ticker} passHref>
                        <Box sx={{ display: "flex", cursor: "pointer" }}>
                            <Box
                                className={styles.Logo}
                                sx={{
                                    backgroundColor: theme === "dark" && !blankImg && !noBackgroundStocks.includes(element.ticker) && !noBackgroundStocks.includes(element.ticker) ? "#c0c0c0" : "transparent",
                                    borderRadius: "4px",
                                    minWidth: "80px",
                                    minHeight: "35px",
                                    height: "min-content",
                                }}
                            >
                                {!imgError && (
                                    <Image
                                        unoptimized
                                        src={element.securityId ? `https://logo.ortex.com/${element.securityId}r.png` : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}
                                        alt={`logo`}
                                        layout="fill"
                                        objectFit="contain"
                                        onError={() => handleImgError(element.securityId)}
                                        onLoad={handleImgLoad}
                                    />
                                )}
                            </Box>
                            <Box sx={{ ml: 1 }}>
                                <span style={{ fontWeight: "bold" }}>{element.ticker + " - "}</span>
                                {element.comments
                                    .filter((comment) => comment.text.length)
                                    .slice(0, 2)
                                    .map((comment, index) => (
                                        <span key={index}>{formatCommentWithDate(comment.text, comment.parameters)}. </span>
                                    ))}
                            </Box>
                        </Box>
                    </Link>
                ))}
            </Box>

            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size={20} />
                </Box>
            ) : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {data.length === 3 ? (
                        <Tooltip title="More" placement="top" arrow>
                            <IconButton
                                onClick={() => fetchMoreData()}
                                sx={{
                                    backgroundColor: dividerColor,
                                    "&:hover": {
                                        backgroundColor: "grey",
                                    },
                                }}
                            >
                                <ExpandMoreIcon sx={{ color: textColor }} />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Less" placement="top" arrow>
                            <IconButton
                                onClick={() => fetchMoreData(3)}
                                sx={{
                                    backgroundColor: dividerColor,
                                    "&:hover": {
                                        backgroundColor: "grey",
                                    },
                                }}
                            >
                                <ExpandLessIcon sx={{ color: textColor }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            )}
        </div>
    );
}
